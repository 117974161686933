import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

function Apply() {
	const APPLY_URL = 'https://forms.gle/FXVHdJFvHjweeqPM6'
	useEffect(() => {
		const isBrowser = typeof window !== 'undefined'

		isBrowser ? (window.location.href = APPLY_URL) : null
	}, [])

	return (
		<>
			<Helmet>
				<title>
					Register your interest to become a Growth Associate at
					Classify! ✨
				</title>
				<meta
					name="description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
				<link rel="canonical" href={APPLY_URL} />

				<meta
					property="og:title"
					content="Register your interest to become a Growth Associate at Classify! ✨"
				/>
				<meta property="og:url" content={APPLY_URL} />
				<meta
					property="og:image"
					content="https://i.imgur.com/pGPo0lL.jpg"
				/>
				<meta
					property="og:description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta
					property="twitter:title"
					content="Register your interest to become a Growth Associate at Classify! ✨"
				/>
				<meta
					name="twitter:image"
					content="https://i.imgur.com/pGPo0lL.jpg"
				/>
				<meta
					property="twitter:description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
			</Helmet>
		</>
	)
}

export default Apply
